
//=============================================================================
// Pantalla Construcción de Formulario
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';
import AICALL from './AICALL';

export default function Formulario() {
  const navigate = useNavigate();
  const SortType = useState('score');
  const [FormID, setFormID] = useState(''); 
  const [Perfil, setPerfil] = useState(''); 
  const [CompName, setCompName] = useState('');
  const [SelectedName, setSelectedName] = useState('');
  const [SelectedMail, setSelectedMail] = useState('');
  const [FetchedData, setFetchedData]     = useState(''); 
  const [Form, setForm] = useState([]); 
  const [CVlist, setCVlist] = useState([]);
  const [SelectList, setSelectList] = useState([]);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [Wait, setWait] = useState(false);

  let baseUrl

  if (sessionStorage.getItem('environmentLog') === 'Local') {
    baseUrl = 'http://localhost:3001';
  } else {
    baseUrl = 'https://candidatos.renee.cl';
  }


  async function AddPreguntaRenee () {
    setWait(true);

    const instruction = 'Genera una pregunta simple sin alternativas relevante para el perfil : ' + Perfil 
    + 'que contemple aspectos técnicos o blandos de la posición'
    
    try {
      const consulta = await AICALL({
        apicall: '04',
        data: instruction
      });
      setForm([...Form, { title: consulta.reply, content: '' , type: 'Texto'}]);
    
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function HandleMailAll( ) {
  
    if (FormID) {
        for (const reply of CVlist) {
          if (SelectedCandidates.includes(reply.name)) {
            try {
              await HandleMail(reply.mail, reply.name);     
  
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }
    } else {
      window.alert('No se logro encontrar ID asociado al formulario que se intenta enviar.')
    }

    window.alert('Formularios enviados a todos los miembros seleccionados');
    setShowPopup(false);
  };
  
  async function HandleMail(mail, name) {

    if (!FormID){
      setFormID(FetchedData.FormID)
    }

    try {
      await LOGCALL({
        apicall: 'FM_SEND',
        code: sessionStorage.getItem('process'),
        Mail : mail,
        Title : FetchedData.Charge_title,
        Name : name,
        E_name: CompName,
        P_name: sessionStorage.getItem('token'),
        P_mail: sessionStorage.getItem('mail'),
        Text : ReplaceSpecialChars(FetchedData.GPToferta)
                .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
                // eslint-disable-next-line
                .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
        FormID : FormID
      });
      saveCV(name);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  async function SaveForm() {
    
    setWait(true);

    if(FormID === ''){
      try {
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          Form: Form,
          FormID : setFormID(Date.now())
        });
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    } else {
      try {
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          Form: Form,
          FormID : FormID
        });
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    }

    try {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setFormID(data.FormID)
    } catch (error) {
      console.error('Error during fetch:', error);
    }

    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  const AddPregunta = () => {
    setForm([...Form, { title: '', content: '' , type: 'Texto'}]);
  };
  
  const AddAlternative = (questionIndex) => {
    const updatedForm = [...Form];
    updatedForm[questionIndex].alternatives = updatedForm[questionIndex].alternatives || [];
    updatedForm[questionIndex].alternatives.push({ 'label': '', 'selected': false, 'correct': false });
    setForm(updatedForm);
  };

  const CopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const DeletePreunta = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };

  const HandleType = (index) => {
    setForm((prevQuestions) => {
      return prevQuestions.map((q, i) => {
        if (i === index) {
          let newType;
          switch (q.type) {
            case 'Texto':
              newType = 'Alt Unica';
              break;
            case 'Alt Unica':
              newType = 'Alt Multiple';
              break;
            case 'Alt Multiple':
              newType = 'Texto';
              break;
            default:
              newType = 'Texto';
          }
          return { ...q, type: newType };
        }
        return q;
      });
    });
  };
  
  const HandleAlternativeChange = (questionIndex, altIndex, value) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      
      if (updatedForm[questionIndex].alternatives[altIndex]) {
        updatedForm[questionIndex].alternatives[altIndex] = {
          ...updatedForm[questionIndex].alternatives[altIndex],
          label: value
        };
      }
      
      return updatedForm;
    });
  };

  const HandleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const HandleCorrect = (questionIndex, altIndex) => {
    const updatedForm = [...Form];
  
    updatedForm[questionIndex].alternatives[altIndex].correct = 
      !updatedForm[questionIndex].alternatives[altIndex].correct;
  
    setForm(updatedForm);
  };

  const MoveUp = (index) => {
    if (index > 0) {
      const newForm = [...Form];
      [newForm[index], newForm[index - 1]] = [newForm[index - 1], newForm[index]];
      setForm(newForm);
    }
  };

  const MoveDown = (index) => {
    if (index < Form.length - 1) {
      const newForm = [...Form];
      [newForm[index], newForm[index + 1]] = [newForm[index + 1], newForm[index]];
      setForm(newForm);
    }
  };

  const OpenConfirmationPopup = () => {
    setSelectedCandidates(SelectList);
    setShowPopup(true);
  };

  const RemoveAlternative = (questionIndex, altIndex) => {
    const updatedForm = [...Form];
    updatedForm[questionIndex].alternatives.splice(altIndex, 1);
    setForm(updatedForm);
  };

  const ReplaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const ToggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };

  async function saveCV( Namedata ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, FormSent: true}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  
  const goto07 = () => { navigate('/P_Candidatos') };  
  const goto09 = () => { navigate('/P_Respuestas') };  

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      
      setPerfil(data.GPTreply)
      setCompName(data.E_name)
      setFetchedData(data)
      if (data.FormID) {
        setFormID(data.FormID)
      }
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
  
      const PreguntaD1 = { 'title': 'Déjanos aca tu numero de teléfono actualizado', 'content': ' ', 'type':'Texto'};
      const PreguntaD2 = { 'title': 'Por favor indícanos tu RUT (lo utilizaremos solo para este proceso, cumpliendo la ley 19.628 de protección de datos personales) Dejar este campo en blanco si no tienes RUT o no quieres proveerlo', 'content': ' ', 'type':'Texto'};
      const PreguntaD3 = { 'title': 'En caso que seas seleccionado, ¿cuál es tu disponibilidad?', 'content': ' ', 'type':'Texto' };
      const PreguntaD4 = { 'title': 'Coméntanos cuál es tu nivel de renta actual o tu última renta en caso que no estés trabajando', 'content': ' ', 'type':'Texto' };
      const PreguntaD5 = { 'title': 'Coméntanos cuál es tus expectativas de renta líquida (puede ser un rango)', 'content': ' ', 'type':'Texto' };

      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: data.FormID
      });
      
      if (data2.length > 0) {
        setForm(data2);
      } else {
        setForm([PreguntaD1, PreguntaD2, PreguntaD3, PreguntaD4, PreguntaD5]);
      }

      if (data.CVData){
        setCVlist(data.CVData);
        setSelectList(Array.isArray(data.SelectList) 
        ? data.SelectList               
        : data.SelectList               
          ? [data.SelectList]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);
  
  //############################################################################################################
  //############################################################################################################
  
  return (
    <div className='BackGround'>

      {/* Popup Envio Mails Masivo */}
      <div className={ShowPopup? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup? 'warningbox' : 'hidden'}>
          <h2>Confirme a quienes se les enviara el formulario</h2>
          <div style={{border:'none'}} className='notas'>
            {CVlist.filter(reply => SelectedCandidates.includes(reply.name)).map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={SelectedCandidates.includes(reply.name) ? 'start-button green' : 
                                                                        'start-button'}
                  onClick={() => {
                    ToggleCandidateSelection(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        checked={SelectedCandidates.includes(reply.name)}
                        onChange={() => {
                          ToggleCandidateSelection(reply.name);
                        }}></input>
              </div>
            ))}
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll(true)}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formularios </button>
            <button onClick={() => setShowPopup(false)}> Cancelar</button>
          </div>
        </div>
      </div>  

      {/* Popup Envio Mail Individual */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'}>
          <h2>Confirme envio de formulario:</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            se le enviara el formulario a: <strong>{SelectedName}</strong> <br /><br />
            con la dirección de correo: <strong>{SelectedMail}</strong> <br /><br />
            O alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
            <button 
              style={{ cursor: 'pointer' }} 
              onClick={() => CopyToClipboard(`${baseUrl}?NM=${encodeURIComponent(SelectedName)}&PID=${encodeURIComponent(FormID)}`)}
            >
              <strong>Has click aqui para copiar la URL del formulario</strong>
            </button>
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleMail(SelectedMail, SelectedName, true); window.alert(` Formulario enviado al candidato ${SelectedName} `); setShowPopup2(false) }}><i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario  </button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Main */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          📑 Vamos a evaluar más profundamente a tus candidatos, Diseñemos un formulario para evaluarlos 📑
        </h2>
      </div>

      <div className='MainBody'>

        {/* Lista de Candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          {/* Cuenta Tope */}
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'> Candidatos : {SelectList.length}</h3> 
          </div>
          {/* Menu Contextural */}
          <div style={{ position:'sticky', 
                        border:'2px solid var(--main)',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        backgroundColor:'var(--light)',
                        top: 0, zIndex: 4, 
                        padding:'1vh'}} >
            <button 
                    onClick={()=> {SaveForm() ; OpenConfirmationPopup()}}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario a todos los candidatos  
            </button>
          </div>
          {/* Lista Candidatos */}
          <div className='notas' style={{borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'15.4vh'}}>
            {CVlist && CVlist.length > 0 ? (
              SortCVlist()
                .filter(reply => SelectList.includes(reply.name))
                .map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    className={ SelectList.includes(reply.name) ? 'start-button green' :  
                                                                  'start-button'}
                  >
                    {reply.name}
                  </button>
                  <button 
                    onClick={() => {
                      SaveForm() ;
                      setSelectedName(reply.name);
                      setSelectedMail(reply.mail);
                      setShowPopup2(true);
                    }}
                    style={{width: '20%'}}
                    className='end-button'
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                </div>
              ))
            ) : (
              <button>No hay candidatos</button>
            )}
          </div>
        </div>
        &nbsp;

        {/* Contenido Formularios */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <h3 className='boxtitle'>Formulario Pre-Screening : {FormID}</h3>
          <div className='notas' style={{overflow: 'scroll', overflowX:'hidden'}}>
            <div style={{display:'flex', marginRight: '3vh',marginTop:'-1vh',paddingTop:'1vh', width:'-webkit-fill-available', position:'fixed', backgroundColor:'var(--light)'}}>
              <button onClick={AddPregunta} style={{marginRight: '1vh'}}> <i className='fa-solid fa-plus'></i> &nbsp; Agrega una Pregunta</button> 
              <button onClick={AddPreguntaRenee} style={{marginRight: '3vh'}}> <i className='fa-solid fa-plus'></i> &nbsp; Agrega una pregunta creada por Renee</button>
            </div>
            <div style={{marginTop: '5vh'}}>
            { Wait === true ? (
              <div>
                <div className='box'>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : Form.map((block, index) => (
              <div key={index} className='notas' style={{ display:'flex', flexDirection:'row', marginBottom: '1vh', marginRight: '1vh', gap: '1vh'}}>
                <div style={{flex: 1}}>
                  <div style={{display:'flex'}}>
                    {index + 1} <textarea
                      value={block.title}
                      className='notas'
                      onChange={(e) => HandleInputChange(index, 'title', e.target.value)}
                      placeholder='Editar título'
                      rows='1'
                      style={{height: '15vh', marginBottom: '1vh' , marginLeft:'1vh'}}
                    />
                  </div>
                  {block.alternatives && block.alternatives.map((alternative, altIndex) => (
                    <div style={{display:'flex', gap:'1vh'}} key={altIndex}>
                      <input
                        type='text'
                        value={alternative.label}
                        className={block.type === 'Texto'? 'hidden' : 'notas'}
                        onChange={(e) => HandleAlternativeChange(index, altIndex, e.target.value)}
                        placeholder={`Alternativa ${altIndex + 1}`}
                        style={{ width: '100%', marginBottom: '1vh', marginLeft:'0.5vh' }}
                      />
                      <button onClick={() => HandleCorrect(index,altIndex)}
                              style={{width:'auto',
                                      marginBottom:'0.5vh',
                              }} 
                              className={block.type === 'Texto' ? 'hidden' : alternative.correct ? 'green' : 'red'}> {alternative.correct ? 'Correcta' : 'Incorrecta'}</button>
                      <button onClick={() => RemoveAlternative(index, altIndex)}
                              style={{width:'5vh', marginBottom:'0.5vh'}}
                              className={block.type === 'Texto'? 'hidden' : 'redb'}> <i className="fa-solid fa-x"></i> </button>
                    </div>
                  ))}
                  <button onClick={() => AddAlternative(index)}
                          className={block.type === 'Texto'? 'hidden' : ''}> <i className='fa-solid fa-plus'></i> &nbsp; Agregar Alternativa</button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '10%'}}>
                  <button onClick={() => MoveUp(index)} style={{marginBottom: '0.5vh'}}> ▲ Mover </button>
                  <button onClick={() => MoveDown(index)} style={{marginBottom: '0.5vh'}}> ▼ Mover </button>
                  <button onClick={() => HandleType(index)} style={{marginBottom: '0.5vh'}}> <i className='fa fa-bars' aria-hidden="true"></i> &nbsp; {block.type} </button>
                  <button className='redb' onClick={() => DeletePreunta(index)} style={{marginBottom: '0.5vh'}}> <i className="fa-solid fa-x"></i> &nbsp; Borrar </button>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>

      </div>
      <div className='bottom'>
        <button onClick={() => { SaveForm() ; goto07()}}>volver</button>
        <button className='Save' onClick={SaveForm}>Guardar Formulario</button>
        <button className='Save last-button' onClick={() => { SaveForm() ; goto09()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
