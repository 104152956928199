import './header.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbox from './Chatbox';

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('chatMessages');
    window.location.replace('https://renee.cl');
};

const namestring = sessionStorage.getItem('token');

const HeaderAdmin = () => {
    const [showChatbox, setShowChatbox] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    let location = useLocation();
    location = location.pathname;

    const goto01 = () => { 
        setIsOpen(false);
        navigate('/');
    };

    const gotoPF = () => {  
        setIsOpen(!isOpen);
        navigate('/MiPerfil');
    };

    const gotoIdeas = () => {  
        setIsOpen(!isOpen);
        navigate('/Ideas');
    };

    const toggleChatbox = () => {
        setIsOpen(false); 
        setShowChatbox((prev) => !prev);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }
    const handleOptionClick = (option) => {
        if (option.action) {
            option.action(); 
        }
    };

    const options = [
      { label: ' MENU ', isDivider: true },
      { label: '🏠 Inicio'                  ,   action: goto01 },
      { label: '💬 Consulta a Reneé'        ,   action: toggleChatbox },
      { label: '🔒 Cambia tu contraseña'    ,   action: gotoPF },
      { label: '📧 Reportes o Sugerencias'  ,   action: gotoIdeas },
      { label: '⛔ Salir de Reneé'          ,   action: logout },
    ];

    return (
        <div>
            <header className='header'>
                <img 
                      src='/favicon3.png' 
                      alt='favicon' 
                      className='logo' 
                      onClick={goto01}
                  />
                <div className='titles'>
                { location.includes('P_') ? (
                    <h3 className='title'>{sessionStorage.getItem('name').toUpperCase() || 'Error'}</h3>          
                ) : (
                    <h3 className='title'>Bienvenido a Reneé || {namestring.toUpperCase()} {sessionStorage.getItem('Grupo').toUpperCase()}</h3>
                )}
                  <button 
                    className='chat-button' 
                    onClick={toggleChatbox} 
                    style={{height:'4vh', width:'4vh'}}> 
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */} 
                    💬 
                  </button>
                  <button 
                    className='chat-button' 
                    onClick={toggleDropdown}
                    style={{height:'4vh', width:'4vh', fontSize:'1.5rem'}}>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </button>
 

     
                </div>
                <div className='subtitle'>
                    <button className={location === '/' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Tus Procesos</h3>
                    </button>
                    <button className={location === '/CrearProceso' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Crear Proceso</h3>
                    </button>
                    <button className={location === '/P_Notas' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Levantamiento de Perfil</h3>
                    </button>
                    <button className={location === '/P_Editar' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Datos del Perfil</h3>
                    </button>
                    <button className={location === '/P_Perfil' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Perfil de Búsqueda</h3>
                    </button>
                    <button className={location === '/P_Oferta' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Aviso de Empleo</h3>
                    </button>
                    <button className={location === '/P_Candidatos' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Revision de candidatos</h3>
                    </button>
                    <button className={location === '/P_Formulario' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Formulario Pre-Screening</h3>
                    </button>
                    <button className={location === '/P_Respuestas' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Respuestas Pre-Screening</h3>
                    </button>
                </div>
                {showChatbox && <Chatbox closeChatbox={toggleChatbox} />}
            </header>
            <div style={{position:'fixed', zIndex:'10'}}>
            <div className={isOpen ? 'outside' : 'hidden'} onClick={toggleDropdown}> 
            </div>
            <div className={`custom-dropdown ${isOpen ? 'active' : ''}`}>
                <div className='dropdown-list'>
                        {options.map((option, index) => (
                            option.isDivider ? (
                                <div
                                    key={index}
                                    className="dropdown-divider">
                                    {option.label}
                                </div>
                            ) : (
                                <div
                                key={index}
                                className={`dropdown-item`}
                                onClick={() => handleOptionClick(option)}>
                                {option.label}
                                </div>
                            )
                        ))}
                </div>
            </div>
            </div>
        </div>
    );
};

export default HeaderAdmin;
