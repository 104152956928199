
// Coleccion Iconos
// <i className="fa-solid fa-paperclip"></i> &nbsp;               Enviar
// <i className="fa-solid fa-x"></i> &nbsp;                       X
// <i className="fa-solid fa-paperclip"></i> &nbsp;               Paperclip
// <i className='fa-solid fa-arrow-down-short-wide'></i> &nbsp;   Flecha Abajo Orden
// <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp;          Flecha Abajo A-Z
// <i className='fa-solid fa-floppy-disk'></i> &nbsp;             Guardar
// <i className='fa-solid fa-file-import'></i> &nbsp;             Importar
// <i className='fa-solid fa-file-export'></i> &nbsp;             Exportar
// <i className='fa-solid fa-plus'></i> &nbsp;                    Agregar
// <i className='fa fa-bars' aria-hidden="true"></i> &nbsp;       Opciones
// <i className='fa-solid fa-copy'></i> &nbsp;                    Copiar
// <i className='fa-regular fa-user'></i> &nbsp;                  Usuario
// <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp;     Generar
// <i className='fa-solid fa-rotate-left'></i> &nbsp;             Volver
// <i className="fa-solid fa-lock-open"></i> &nbsp;               Desbloquear
// <i className="fa-solid fa-lock"></i> &nbsp;                    Bloquear
//=============================================================================
// Pantalla Mail de Sugerencias
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function Ideas() {

  const navigate = useNavigate();

  const [Text, setText]   = useState('');
  const [Title, setTitle]   = useState('');
  const [Error, setError]     = useState('');
  const [Section, setSection]   = useState('');
  const [wait, setWait] = useState(false);

   const handleSubmit = async e => {
		setWait(true)
    setError('')
		e.preventDefault();
    const result = await LOGCALL({
      apicall: 'US_ERROR',
      Title,
      Section,
      Text
    });

    setWait(false)
    setTimeout(() => {
    }, 500);
    if (result) {
      alert(result.reply || ' ');
      navigate('/')
    } 
    else {
      alert(result.error || ' ');
    }
	};

  const goto01 = () => { navigate('/') };

  useEffect(() => {
  }, []);

  return (
    <div className='BackGround'>
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >Si encontraste un error, o tienes alguna sugerencia, siéntete libre de comunicarlo!</h2>
			</div>
      <div className='MainBody'>

      <div>
        <div style={{display:'flex', alignItems:'stretch', height:'100%'}}>
          <form onSubmit={handleSubmit} style={{width:'200%', marginRight:'1vh'}}>
          
            <label><strong>Escribe un Titulo corto del error encontrado o de tu sugerencia</strong>
              <input  type='text' 
                required
                style={{paddingLeft:'0vh', height:'4vh'}}
                className={Title ? 'highlightedItem' : ''}
                onChange={e => setTitle(e.target.value)}/>
            </label>
              
            <label><strong>¿Cual es la sección o proceso asociado al error o sugerencia?</strong>
              <input  type='text' 
                required
                style={{paddingLeft:'0vh', height:'4vh'}}
                className={Section ? 'highlightedItem' : ''}
                onChange={e => setSection(e.target.value)}/>
            </label>
            <label><strong>Indica con mas detalle el error encontrado o tu idea que quisieras ver implementada</strong>
              <textarea  
                required
                className='notas'
                style={{ height:'30vh'
                }}
                onChange={e => setText(e.target.value)}/>
            </label>
          
            <button type='submit' >Enviar</button>
          
          </form>
          <div className='notas'> 
            <h3>Si encontraste algun error durate tu uso de Reneé, o tienes alguna sugerencia para mejorar la herramienta, sientete libre de compartirlo. Este mensaje sera enviado al area de desarrollo para que lo tengan en consideracion para la siguiente version de reneé</h3> 
            <h3>Los mensajes enviados son recibidos de manera anónima</h3>
          </div>
        </div>
        {Error && <div className='error'>{Error}</div>}
        {wait === false ? (
          <div>
            <br />
          </div>
        ) 
        : (
          <div className='loader-container'>
            <div className='pulsing-dot'></div>
          </div>
        )}
      </div>

        
      </div>

      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <div></div>
      </div>
    </div>
  );
}
