
//=============================================================================
// Pantalla Nuevo Usuario
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function Puser() {

  const navigate = useNavigate();

  const [U_mail, setU_mail]   = useState('');
  const [U_pass, setU_pass]   = useState('');
  const [U_pass2, setU_pass2]   = useState('');
  const [Error, setError]     = useState('');
  const rol                   = 'User';

  const [wait, setWait] = useState(false);

   const handleSubmit = async e => {
		setWait(true)
    setError('')
		e.preventDefault();
    if (U_pass === U_pass2){
      const result = await LOGCALL({
        apicall: 'US_EDIT',
        U_mail,
        U_pass,
        rol
      });
  
      setWait(false)
      setTimeout(() => {
      }, 500);
      if (result) {
        alert(result.reply || ' ');
        navigate('/')
      } 
      else {
        alert(result.error || ' ');
      }
    } else {
      alert(' las claves ingresadas no son iguales  ')
    }
	};

  const goto01 = () => { navigate('/') };

  useEffect(() => {
    setU_mail(sessionStorage.getItem('mail'))
  }, []);

  return (
    <div className='BackGround'>
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >Hey, Vamos a integrar a un nuevo miembro a renee!</h2>
			</div>
      <div className='MainBody'>
        
        <div>
          <div>
            <form onSubmit={handleSubmit} style={{width:'100%'}}>

              <label><strong>Clave Nueva</strong>
               <input  type='password' 
                  style={{height:'4vh'}}
                  className={U_pass ? 'highlightedItem' : ''}
                  onChange={e => setU_pass(e.target.value)}/>
               </label>
               
               <label><strong>Confirme su nueva clave</strong>
               <input  type='password' 
                  style={{height:'4vh'}}
                  className={U_pass2 ? 'highlightedItem' : ''}
                  onChange={e => setU_pass2(e.target.value)}/>
               </label>

              <button style={{marginLeft:'1.5vh'}} type='submit' >Guardar</button>

            </form>
            {Error && <div className='error'>{Error}</div>}
            {wait === false ? (
              <div>
                <br />
              </div>
            ) 
            : (
              <div className='loader-container'>
                <div className='pulsing-dot'></div>
              </div>
            )}
          </div>
        </div>

        
      </div>

      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <div></div>
      </div>
    </div>
  );
}
