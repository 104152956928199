
//=============================================================================
// Pantalla Recuperación de Perfiles
//=============================================================================

import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';
import AICALL from './AICALL';
import StarRating from '../components/star';
import jsPDF from 'jspdf';

export default function Recuperar() {

  const navigate = useNavigate();
  const [Score, setScore] = useState('0');
  const [SortType, setSortType] = useState('score');
  const [ScreenType, setScreentype] = useState('perfil');
  const [IDlist,setIDlist] = useState([]);
  const [CVlist, setCVlist] = useState([]);
  const [CompName, setCompName] = useState('');
  const [FetchedData, setFetchedData]     = useState(''); 
  const [SelectList, setSelectList] = useState([]);
  const [SelectQuest, setSelectQuest] = useState([]);
  const [Wait, setWait] = useState(false);
  const FileInputRef = useRef(null);
  const [CV, setCV] = useState(null);
  const [SelectedCVId, setSelectedCVId] = useState(null);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [ShowPopup, setShowPopup3] = useState(false);
  const ProcessedIds = new Set();

  async function ExportToPDFCV ( ciego ) {
    setWait(true);
    const doc = new jsPDF({
      format: 'a4',
      compress: true,
    });
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 8;
  
    const logoRenee = new Image();
    logoRenee.src = '/favicon.png';
    const logoWidth = 25;
    const logoHeight = 25;
  
    const leftColumnWidth = (pageWidth - 3 * margin) * 0.35;
    const rightColumnWidth = (pageWidth - 3 * margin) * 0.65;
    const fontsize = 10;
    const titleFontSize = 12;
    const contentMarginTop = 12;
    let yLeft = contentMarginTop;
    let yRight = contentMarginTop;
  
    const drawLeftColumnBackground = () => {
      doc.setFillColor(222, 237, 254);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, pageHeight, 'F');
    };
  
    drawLeftColumnBackground();
  
    var img = new Image();
    img.src = CV.personalInfo.picture ? CV.personalInfo.picture + '?not-from-cache-please' : '/default.png';
  
    doc.addImage(img, 'JPEG', margin, margin, 40, 40);
    yLeft = margin + 40 + 10;
  
    const printText = (text, x, y, isTitle = false, columnWidth, addMargin = true) => {
      const fontStyle = isTitle ? 'bold' : 'normal';
      const fontSize = isTitle ? titleFontSize : fontsize;
  
      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(fontSize);
  
      const lineHeight = fontSize * 0.5;
      const textLines = doc.splitTextToSize(text, columnWidth);
  
      textLines.forEach((subLine) => {
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          drawLeftColumnBackground();
          yLeft = contentMarginTop;
          yRight = contentMarginTop; 
        }
        doc.text(x, y, subLine);
        y += lineHeight;
      });
      if (addMargin) y += 4; 
      return y;
    };
  
    const [day, month, year] = CV.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    const leftColumnData = [
      `${CV.personalInfo.firstName} ${CV.personalInfo.lastName} \n ${age} Años`,
      `${CV.presentation}`,
    ];
  
    if (!ciego) {
      leftColumnData.push(
        `Email: \n ${CV.personalInfo.emails.primaryEmail}`,
        `Teléfono: \n ${CV.personalInfo.phoneNumbers[0].number}`,
        `Dirección: \n ${CV.personalInfo.address ? CV.personalInfo.address + ', ' : ''} ${CV.personalInfo.communeName || ''}, Región ${CV.personalInfo.regionName || ''}`,
        'Redes sociales',
        `LinkedIn: \n ${CV.socialNetworks.linkedinLink || ' - - - '}`,
        `Facebook: \n ${CV.socialNetworks.facebookLink || ' - - - '}`
      );
    }
  
    const rightColumnData = [
      'Experiencia Laboral',
        ...CV.workExperience
          .slice() 
          .sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate)) 
          .flatMap(job => [
            `${job.companyName} | ${job.jobPosition}`,
            `${job.fromDate} - ${job.toDate || 'Presente'}`,
            ...job.achievements.split('\n').map(achievement => `• ${achievement}`).filter(line => line.trim() !== ''),
            '',
          ]),
      'Educación',
      ...CV.studies.higherEducation
        .slice() 
        .sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear))
        .flatMap(edu => [
        `${edu.otherCareer ? edu.otherCareer + '|' : ''}${edu.minor ? edu.minor : ''}`,
        `${edu.institution?.name || edu.otherInstitution || ''}`,
        `${edu.entryYear} - ${edu.graduationyear || 'Presente'}`,
        '', 
      ]),
    ];
  
    const fillColumns = (leftData, rightData) => {
      let leftIndex = 0;
      let rightIndex = 0;
    
      while (leftIndex < leftData.length || rightIndex < rightData.length) {
        if (yLeft + fontsize * 0.5 > pageHeight - margin && yRight + fontsize * 0.5 > pageHeight - margin) {
          doc.addPage();
          drawLeftColumnBackground();
          yLeft = contentMarginTop;
          yRight = contentMarginTop;
        }
    
        if (leftIndex < leftData.length) {
          yLeft = printText(leftData[leftIndex], margin, yLeft, leftIndex === 0, leftColumnWidth);
          leftIndex++;
        }
    
        if (rightIndex < rightData.length) {
          if (yRight + fontsize * 0.5 > pageHeight - margin) {
            doc.addPage();
            drawLeftColumnBackground();
            yLeft = contentMarginTop;
            yRight = contentMarginTop; 
          }
          yRight = printText(rightData[rightIndex], leftColumnWidth + 2 * margin, yRight, rightIndex === 0, rightColumnWidth);
          rightIndex++;
        }
      }
    };
    
  
    fillColumns(leftColumnData, rightColumnData);
  
    const addFooterAndPageCount = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageText = `Pagina ${i} de ${pageCount}`;
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.text(margin, pageHeight - margin, pageText, { align: 'left' });
  
        doc.addImage(logoRenee, 'PNG', pageWidth - logoWidth - margin, pageHeight - logoHeight - margin, logoWidth, logoHeight);
      }
    };
  
    addFooterAndPageCount(doc);

    if (!ciego) {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}.pdf`);
    } else {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}_Ciego.pdf`);
    }

    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function HandleMailAll(state) {
  
      if (state === true) {
        for (const reply of CVlist) {
          if (SelectedCandidates.includes(reply.name)) {
            try {
              await HandleMail(reply.mail, reply.name, state);     
  
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }
      } else {
        for (const reply of CVlist) {
          if (!SelectedCandidates.includes(reply.name)) {
            try {
              await HandleMail(reply.mail, reply.name, state);     
  
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }
      }

    window.alert('Formularios enviados a todos los miembros seleccionados');
    setShowPopup3(false);
  };
  
  async function HandleMail(mail, name, state) {

    try {
      await LOGCALL({
        apicall: 'FM_REJ1',
        code: sessionStorage.getItem('process'),
        Mail : mail,
        Title : FetchedData.Charge_title,
        Name : name,
        E_name: CompName,
        P_name: sessionStorage.getItem('token'),
        P_mail: sessionStorage.getItem('mail'),
        Text : ReplaceSpecialChars(FetchedData.GPToferta)
                .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
                // eslint-disable-next-line
                .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
      });
      saveCV(name);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  async function HandleRatingChange ( newRating ) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVId
          ? { ...cv, stars: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: SelectedCVId, stars: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };

  async function HandleIDFetch () {
    let input = ''
    if (IDlist && Array.isArray(IDlist)){
      input = prompt(
        'IDs actuales:\n' +
        IDlist.map(id => '> ' + id).join('\n') + 
        '\n Ingrese el ID del proceso:'
      );
    } else if (IDlist) {
      input = prompt(
        'IDs actuales:\n' +
        IDlist + 
        '\n Ingrese el ID del proceso:'
      );
    } else {
      input = prompt(
        '\n Ingrese el ID del proceso:'
      );
    }
    if (input !== null && !IDlist.includes(input)) {
      try {
        IDlist.push(input);
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          JobId: IDlist
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
      setCV(null); 
      HandleCandidatos(input); ;
    }
  };
  
  async function HandleReneeopinion( CV , name ) {

    const instruction = 'EL Perfil: ' + sessionStorage.getItem('currentprofile') 
                 + '. \n El candidato: ';

    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, Nota: null } 
          : cv
      )
    );

    try {
      const consulta = await AICALL({
        apicall: '05',
        data: instruction + JSON.stringify(CV.personalInfo) + JSON.stringify(CV.workExperience) + JSON.stringify(CV.studies)
      });

      const parsedconsulta = JSON.parse(consulta.reply)
      
      setCVlist(prevCVlist =>
        prevCVlist.map(cv =>
          cv.name === name
            ? { ...cv, Nota: parsedconsulta.Evaluacion } 
            : cv
        )
      );
      return parsedconsulta.Evaluacion
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    
  };

  async function HandleSelect( name ) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = SelectList.includes(name)
      ? SelectList.filter(id => id !== name)
      : [...SelectList, name];
  
    setSelectList(updatedSelectList);
    
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  
  async function HandleCandidatos( data ) {

    setWait(true);

    sessionStorage.setItem('currentsave' , sessionStorage.getItem('process'))
    sessionStorage.setItem('currentprofile' , sessionStorage.getItem('perfil'))

    try {
      const reply = await APICALL({
        apicall: 'TB_list',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        jobID: data
      });


      let list = [];
      if (reply) {
        list = reply.data.sharedPostulations || [];
  
        const cvPromises = list.map(async (applicant) => {
          if (!ProcessedIds.has(applicant.applicantId)) {
            ProcessedIds.add(applicant.applicantId);
            await HandleCV(applicant.applicantId, data, applicant.questions);
          } 
        });
  
        await Promise.all(cvPromises);
      }
    } catch (error) {
      // console.error('Error during fetch:', error);
    }

    setTimeout(() => {
      setWait(false);
    }, 250);

  };

  async function saveCV( Namedata ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, Rejected: true}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function HandleCV( data , jid , questions ) {
  
    try {
      const reply = await APICALL({
        apicall: 'TB_fetch',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });
  
      const { personalInfo, socialNetworks, workExperience, presentation, studies } = reply.data;
      const name = `${personalInfo.firstName} ${personalInfo.lastName}`;
      const rut  = `${personalInfo.idNumberFormat}`;
      const mail = `${personalInfo.emails.primaryEmail}`;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies };
  
      setCVlist(prevCVlist => {
        const existingCV = prevCVlist.find(cv => cv.id === data);
  
        if (reply && !existingCV) {
          const updatedList = [...prevCVlist, { name: name, id: data, rut: rut, new: true }];
          HandleReneeopinion(cv, name).then(nota => {
            SaveCV(name, mail, data, jid, rut, nota, questions);
            setCVlist(prevCVlist =>
              prevCVlist.map(cv =>
                cv.name === name
                  ? { ...cv, questions: questions} 
                  : cv
              )
            );
          });
          
          return updatedList;
        } else {
          return prevCVlist;
        }
      });
  
    } catch (error) {
      console.error('Error during fetch:', error);
    } 
  };

  async function HandleCVpropio() {
    const curriculum = CV.multipleDocuments.dataDocument.find(
      doc => doc.documentTypeName === 'Currículum adicional'
    );

    if (curriculum) {
      window.open(curriculum.documentPath);
    } else {
      console.error('Document not found.');
    }
  };

  async function HandleFileChange(event) {
    setWait(true);
  
    const files = event.target.files; 
    const promises = Array.from(files).map(async (file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', '06');
  
      try {
        const reply = await AICALL(data);
        const formatedreply = reply.reply
          .replace(/```json\n/, '')
          .replace(/\n```/, '');
        const parsedreply = JSON.parse(formatedreply);
        const Namedata = `${parsedreply.personalInfo.firstName} ${parsedreply.personalInfo.lastName}`;
        const RUT = parsedreply.personalInfo.idNumberFormat;
  
        try {
          await APICALL({
            apicall: 'CV_create',
            code: sessionStorage.getItem('process'),
            U_emp: sessionStorage.getItem('Grupo'),
            Name: Namedata,
            CVData: parsedreply,
          });
        } catch (error) {
          console.error('Error during fetch:', error);
          return { token: null };
        }
  
        SaveCV(
          Namedata,
          parsedreply.personalInfo.emails.primaryEmail,
          'CVE_' + Namedata,
          IDlist.at(-1),
          RUT
        );
        setCVlist((prevCVlist) => [
          ...prevCVlist,
          { name: Namedata, id: 'CVE_' + Namedata, rut: RUT, new: true },
        ]);
      } catch (error) {
        console.error('Error processing file:', file.name, error);
      }
    });
  
    await Promise.all(promises);
  
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function LookCV( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        console.table(obj)
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
      return(cv)
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null);
      return([])
    }

  };
  
  async function SaveCV( Namedata , mail , id , jid , rut, score , questions , stars ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, mail: mail, id: id, JID: jid, RUT: rut, Nota: score, questions: questions, stars: stars}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  
  const HandleButtonClick = () => {
    FileInputRef.current.click();
  };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const ReplaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };


  const FormatJobData = (jobData) => {
    const title = '<h2>Experiencia Laboral:</h2>\n\n';
  
    if (!jobData || jobData.length === 0) {
      return '<h2>No hay experiencia laboral disponible</h2>';
    }
  
    const sortedJobs = jobData.slice().sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements.includes('\n?') 
        ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
        : job.achievements.split('\n').filter(line => line.trim() !== '');

      const cleanedAchievements = achievements.map(achievement => 
        achievement.replace(/^[*•-]\s*/, '').trim()
      );
  
      const formattedAchievements = cleanedAchievements.map(achievement => `● ${achievement}`).join('</br>');
  
      return `
          <div>
            <strong style='color: #333;'>${job.jobPosition ? job.jobPosition.toUpperCase() : ''}</strong> </br>
            <span style='color: #666;'>${job.companyName} || ${job.companyActivity ? job.companyActivity.description : ''}</span></br>
            <span style='color: #666;'>${job.fromDate} - ${job.toDate || 'Presente'}</span></br>
            <p style='color: #666;'>${formattedAchievements}</p>
            <hr style='border: 1px solid #ccc;'/>
          </div>
      `.trim();
    }).join('</br>');
    
    return `${title}${formattedJobs}`;
  };

  const OpenRejectionPopup = () => {
    setSelectedCandidates(SelectList);
    setShowPopup3(true);
  };

  const ToggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };
  
  const FormatStudyData = (studyData) => {
    const title = '<h2>Formación Académica:</h2>\n\n';
  
    if (!studyData || !studyData.higherEducation || studyData.higherEducation.length === 0) {
      return '<h2>No hay formación académica disponible</h2>';
    }
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));
  
    const formattedStudies = sortedStudies.map(study => {
      return `
          <div>
            <strong style='color: #333;'>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}</strong>
            ${study.minor ? `<span style='color: #666;'> - ${study.minor.toUpperCase()}</span>` : ''}</br>
            <span style='color: #666;'>${study.otherInstitution || ''}</span></br>
            <span style='color: #666;'>${study.statusName || ''}</span></br>
            <span style='color: #666;'>${study.entryYear} ~ ${study.graduationyear || 'Presente'}</span></br>
            <hr style='border: 1px solid #ccc;'/>
          </div>
      `.trim();
    }).join('</br>');
  
    return `${title}${formattedStudies}`;
  };
  
  const FormatPersonalData = ( PD ) => {
    const [day, month, year] = PD.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? `, ${age} años` : '' }</strong> \n\n ${PD.presentation || 'Sin Descripción'}
      
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${PD.socialNetworks.linkedinLink ? `<a href='${PD.socialNetworks.linkedinLink}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` : ' - - -'}
      
      🏠 ${PD.personalInfo.communeName} 
            Region ${PD.personalInfo.regionName} ${PD.personalInfo.residenceCountry ? `, ${PD.personalInfo.residenceCountry.description}` : ''}

      ☎️ ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ ${PD.personalInfo.emails.primaryEmail || 'N/A'}
    `.trim();
  };
  
  const FormatQuestions = ( questions ) => {
    let formatted = '';

    if (questions) {
      Object.keys(questions).forEach(key => {
        if (key.includes('question')) {
          const answerKey = key.replace('question', 'answer');
          const cleanQuestion = questions[key].replace(/&#34;/g, '"').trim(); 
          const answer = questions[answerKey] ? questions[answerKey] : '';
          formatted += `<div style='margin-top: 20px;'>`; 
          formatted += `<strong style='color: #333;'>${cleanQuestion}</strong>`; 
          formatted += `<p style='color: #666; margin-top: 0.5vh'>${answer}</p>`; 
          formatted += `<hr style='border: 1px solid #ccc;'/>`; 
          formatted += `</div>`;
          }
      });
      
      return formatted;
    } else {
      return '<h2> No hay respuestas disponibles </h2>';
    }
  };
  
  const goto01 = () => { navigate('/') };
  const goto08 = () => { navigate('/P_Formulario') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      setCompName(data.E_name)
      setFetchedData(data)
      if (data.CVData){
        setCVlist(data.CVData);
        setSelectList(Array.isArray(data.SelectList) 
        ? data.SelectList        
        : data.SelectList             
          ? [data.SelectList]        
          : []);
      }
      if (data.JobId && Array.isArray(data.JobId)){
        setIDlist(data.JobId);
        data.JobId.forEach(id => {
          HandleCandidatos(id);
        });
      } else if (data.JobId && !Array.isArray(data.JobId)) {
        setIDlist(data.JobId)
        HandleCandidatos(data.JobId);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Popup Envio Mails Rechazo Masivo */}
      <div className={ShowPopup? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup3(false)} >
        </div>
        <div className={ShowPopup? 'warningbox' : 'hidden'}>
          <h2>Confirme a quienes se les enviara el Mensaje de rechazo</h2>
          <div style={{border:'none'}} className='notas'>
            {CVlist.filter(reply => !SelectList.includes(reply.name))
                   .filter(reply => !reply.Rejected)
                   .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '0.5vh' }}>
                <button
                  className={!SelectedCandidates.includes(reply.name) ? 'start-button red' : 
                                                                        'start-button'}
                  onClick={() => {
                    ToggleCandidateSelection(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        checked={!SelectedCandidates.includes(reply.name) && !reply.Rejected}
                        onChange={() => {
                          ToggleCandidateSelection(reply.name);
                        }}></input>
              </div>
            ))}
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll(false)}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Notificaciones </button>
            <button onClick={() => setShowPopup3(false)}> Cancelar</button>
          </div>
        </div>
      </div>  

      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        👍 Hey, revisemos los candidatos que he reclutado para tu proceso y mi recomendación! 👍 
        </h2> 
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          {/* Botones superiores */}
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Candidatos : {CVlist.length}</h3> 
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'score') {
                  setSortType('stars'); 
                } else if (SortType === 'stars') {
                  setSortType('name'); 
                } else {
                  setSortType('score'); 
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  % <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  ☆ <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  A-Z <i class='fa-solid fa-arrow-down-a-z'></i>
                </>
              )}
            </button>
          </div>
          {/* Menu interno */}
          <div style={{ position:'sticky', 
                        border:'2px solid var(--main)',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        backgroundColor:'var(--light)',
                        top: 0, zIndex: 4, 
                        padding:'1vh'}} >
              <div style={{display:'flex', flexDirection:'row', gap:'1vh'}}>
                <button onClick={HandleIDFetch}>
                  <i class="fa-solid fa-plus"></i> &nbsp; Agregar ID
                </button>
                <button onClick={HandleButtonClick}> 
                  <i className='fa-solid fa-file-import'></i> &nbsp; Importar CV 
                </button>
              </div>
              <button style={{marginTop:'1vh'}}
                      onClick={OpenRejectionPopup}>
                <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;  Notificar a no seleccionados 
              </button>
          </div>
          {/* Lista */}
          <div className='notas' style={{borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'15.4vh'}}>
            <input 
              type='file' 
              className='hidden'
              ref={FileInputRef} 
              onChange={HandleFileChange} 
              multiple
            />
            {CVlist && CVlist.length > 0 ? (
              SortCVlist().map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    style={{  borderColor: reply.Rejected ? 'gray' : '', 
                              backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                              marginLeft:'1vh' }}
                    className={ reply.name === SelectedCVId  ?  'start-button highlighted' : 
                                                reply.new?      'start-button green' : 
                                                                'start-button'}
                    onClick={() => {
                      LookCV(reply.id);
                      setSelectedCVId(reply.name);
                      setSelectQuest(reply.questions);
                      setScore(reply.stars);
                      ;
                    }}
                  >
                    {reply.name}
                  </button>
                  <button className={ !reply.Nota ? 'middle-button' : 
                                                    JSON.parse(reply.Nota) < 50 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.Nota) < 70 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  borderRight:'none',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                                  maxWidth:'3vw'}}
                          onClick={() => {
                            setWait(true);
                            setSelectedCVId(reply.name);
                            setSelectQuest(reply.questions);
                            setScore(reply.stars);
                            LookCV(reply.id).then(CV => {
                              HandleReneeopinion(CV, reply.name).then(nota => {
                                console.log(reply)
                                if (reply.RUT){
                                  SaveCV(reply.name, reply.mail, reply.id, reply.JID, reply.RUT, nota);
                                } else {
                                  SaveCV(reply.name, reply.mail, reply.id, reply.JID, '', nota);
                                }
                                setTimeout(() => {
                                  setWait(false);
                                }, 250);
                              });
                            })
                          }}>
                    {reply.Nota ? JSON.parse(reply.Nota) : ' - '}{'%'}
                  </button>
                  <button className={ !reply.stars ? 'middle-button' : 
                                                    JSON.parse(reply.stars) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  borderRight:'none',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                                  maxWidth:'3vw'}}>
                    {reply.stars ? JSON.parse(reply.stars) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                          }}
                          checked={SelectList ? SelectList.includes(reply.name) : false}
                          onChange={() => {
                            HandleSelect(reply.name);
                          }}></input>
                </div>
              ))
            ) : (
              <button className='PublicButoff'>No hay candidatos</button>
            )}
          </div>
        </div>
        &nbsp;

        {/* Perfil del Candidato */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <h3 className='boxtitle'>Perfil personal</h3>
          <div className='notas'>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <h2
                className={CV ? 'hidden' : ''}> 
                Inicie seleccionando un candidato 
                {/* eslint-disable-next-line */}
                <p style={{fontSize:'4rem'}}>👈</p>
              </h2>
              <img
                className={CV ? '' : 'hidden'}
                src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                alt='Profile'
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  position:'stick'
                }}
              />
              <div className={CV ? '' : 'hidden'}>
                <StarRating initialRating={Score} onRatingChange={HandleRatingChange} />
              </div>

              <button className={CV ? sessionStorage.getItem('rol') === 'Admin' ? '' : 'hidden' : 'hidden'} style={{marginBottom:'0.5vh'}}
                      onClick={() => {const Name = CV.personalInfo.firstName + ' ' + CV.personalInfo.lastName;
                                      SaveCV(Name, CV.personalInfo.emails.primaryEmail);
                                      window.alert('Mail Reimportado:  \n' + CV.personalInfo.emails.primaryEmail)
                                      }}>
                        Reimportar mail</button>

              <details style={{width:'-webkit-fill-available'}}>
                <summary className={CV ? '' : 'hidden'} style={{marginBottom:'0.5vh'}}> <i className='fa-solid fa-file-export'></i> &nbsp; Exportar CV</summary>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVId ? '' : 'hidden'} onClick={() => ExportToPDFCV( false )}>
                    CV Completo Reneé</button>
                  <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVId ? '' : 'hidden'} onClick={() => ExportToPDFCV( true )}>
                    CV Ciego Reneé</button>
                  {CV?.multipleDocuments?.dataDocument?.some(doc => doc.documentTypeName === 'Currículum adicional') && (
                  <button style={{marginBottom:'0.5vh', width:'80%'}} onClick={HandleCVpropio}>
                    CV propio</button>)}
                </div>
              </details>
              <div
                dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                style={{
                  whiteSpace: 'pre-wrap',
                  textAlign:'start'
                }}
              />
            </div>
          </div>
        </div>
        &nbsp;

        {/* Detalles del Perfil */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>{ScreenType === 'perfil' ? 'Perfil del candidato' : 'Preguntas Introductorias'}</h3>
            <button className='sortbutton On'
                    onClick={() => setScreentype(ScreenType === 'perfil' ? 'preguntas' : 'perfil')}
                    style = {{ textAlign:'left' , padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {ScreenType === 'perfil' ? ' ir a Preguntas Introductorias' : ' ir a Perfil del candidato'}
            </button>
          </div>
          { Wait === true ? (
              <div className='notas'>
                <div className='box'>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : ScreenType === 'perfil' ? (
              <div
              className= 'notas'
              dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                    + '\n\n\n\n'
                                                    + FormatStudyData(CV.studies) : '' }}
              style={{
                overflowY: 'auto',
                textAlign: 'left'
              }}
              />
            ) : (
              <div
              className= 'notas'
              dangerouslySetInnerHTML={{ __html: CV ? FormatQuestions(SelectQuest) : '' }}
              style={{
                whiteSpace: 'pre-wrap',
                overflowY: 'auto',
                textAlign: 'left'
              }}
              />
            )}

        </div>

      </div>
      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <button className = {sessionStorage.getItem('rol') === 'Admin' ? '' : 'hidden' } onClick={() => HandleReneeopinion(CV,SelectedCVId)}>Consulta Manual a Renee</button>
        <button className='last-button' onClick={goto08}>Continuar</button>
      </div>
    </div>
  );
} 
