
//=============================================================================
// Pantalla Generación Oferta/ Preguntas | Publicación
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [Oferta, setOferta] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [Pregunta1, setPregunta1] = useState('');
  const [Pregunta2, setPregunta2] = useState('');
  const [Pregunta3, setPregunta3] = useState('');
  const [Pregunta4, setPregunta4] = useState('');
  const [instruccion, setInstruccion] = useState('');
  const [FetchedData, setFetchedData] = useState(''); 
  const [IDlist,setIDlist] = useState([]);
  const [Claves, setClaves] = useState(['']);
  const [Wait, setWait] = useState(false);
  const [Wait2, setWait2] = useState(false);
  const [Regen, setRegen] = useState(false);
  const [Actual, setActual] = useState(false);
  const [Publicar, setPublicar] = useState(false);
  const [Aviso, setAviso]     = useState(false);
  const [ShowPopup, setShowPopup]     = useState(false);
  const handleChange = (event) => { setOferta(event.target.value) };

  const Notas1 = 'Genera En el titulo utiliza el formato : '
                  + 'Buscamos {nombre del cargo} para compañía de {industria donde se busca al candidato}.'
                  + 'Genera El aviso como un proceso de búsqueda para la empresa' 
                  + sessionStorage.getItem('grupo')
                  + 'En el texto generado refiere a la empresa como "nuestro cliente" en lugar de su nombre';

  const Notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const Notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';

  async function ExportToTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  async function ExportToYAPO() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'YP_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  async function FetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    localStorage.removeItem('fetched');
    localStorage.setItem('fetched', data);
    setOferta(data.GPToferta);
    if (!data.GPToferta){
      GenerateOferta();
    } 
    if (!data.GPTpreguntas){
      GeneratePreguntas();
    }
  };

  async function GenerateOferta(notas) {
    setWait(true);
    setOferta('Espera un momento mientras Reneé Trabaja ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: localStorage.getItem('reply') 
              + localStorage.getItem('fetched')
              + notas
      });
      setOferta(FormatResponse(JSON.parse(newText.reply)));
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function GeneratePreguntas() {
    setWait2(true);
    if (!Final1){
      setPregunta1('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final2){
      setPregunta2('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final3){
      setPregunta3('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final4){
      setPregunta4('Espera un momento mientras Reneé Trabaja ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.getItem('reply') + localStorage.getItem('fetched')
      });
    
      const parsedReply = JSON.parse(newText.reply);
    
      if (!Final1) {
        setPregunta1(parsedReply.Pregunta_1);
      }
      if (!Final2) {
        setPregunta2(parsedReply.Pregunta_2);
      }
      if (!Final3) {
        setPregunta3(parsedReply.Pregunta_3);
      }
      if (!Final4) {
        setPregunta4(parsedReply.Pregunta_4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
    setTimeout(() => {
      setWait2(false);
    }, 250);
  };

  async function HandleIterateText() { 
    if (Aviso){
      setWait(true);
    } else {
      setWait2(true);
    }
    // console.log(text);
    try {
      const response = await AICALL({
        apicall: Aviso ? '02' : '03',
        data: Aviso ? `${Oferta} ${instruccion}` 
                    : `${Pregunta1} ${Pregunta2} ${Pregunta3} ${Pregunta4} ${instruccion}`
                    + 'Asegurate de mantener el mismo formato que el texto original sin agregar elementos adicionales'
                    + '. Notas Adicionales: '
                    + localStorage.getItem('notas')  
                    + localStorage.getItem('archivo')

      });
      if ( Aviso ) {
        setOferta(FormatResponse(JSON.parse(response.reply)));
      } else {
        const parsedReply = JSON.parse(response.reply)
        setPregunta1(parsedReply.Pregunta_1);
        setPregunta2(parsedReply.Pregunta_2);
        setPregunta3(parsedReply.Pregunta_3);
        setPregunta4(parsedReply.Pregunta_4);
      }
      setInstruccion('');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 1000);;
   };

  async function SaveData() {
    setWait(true);
    setWait2(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        Claves: Claves,
        GPToferta: Oferta,
        GPTpreguntas: [Pregunta1.replace(/"/g, '') , Pregunta2.replace(/"/g, '') , Pregunta3.replace(/"/g, '') , Pregunta4.replace(/"/g, '')]
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setWait(false);
        setWait2(false);
      }, 250); 
    }
  };

  async function SaveJobId(ID) {
    if (!IDlist.includes(ID)) {
      IDlist.push(ID);
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        JobId: IDlist.filter(item => {return item !== null && item !== undefined && item !== ''})
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function UpdateTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_update',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  // const exportToPDFoferta = () => {
  //   const doc = new jsPDF();

  //   doc.text(oferta, 10, 10);
  //   doc.save('oferta.pdf');
  // };

  const FormatResponse = (jobDetails) => {
    
    let formattedText = '';

    formattedText += jobDetails.Descripcion_de_la_empresa+ '\n\n';
  
    formattedText += `**Misión del Cargo:**\n${jobDetails.Mision_del_cargo}\n\n`;
  
    formattedText += `**Responsabilidades:**\n`;
    jobDetails.Responsabilidades.forEach((responsabilidad, index) => {
      formattedText += `~${responsabilidad}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Requisitos Clave:**\n`;
    jobDetails.Claves.forEach((clave, index) => {
      formattedText += `~${clave}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Oferta:**\n`;
    formattedText += `~Banda Salarial: ${jobDetails.Oferta.banda_salarial}~\n`;
    formattedText += `~Horario: ${jobDetails.Oferta.horario}~\n`;
    formattedText += `~Modalidad de Trabajo: ${jobDetails.Oferta.modalidad_de_trabajo}~\n`;
    formattedText += `~Beneficios Adicionales: ${jobDetails.Oferta.beneficios_adicionales}~\n`;
    formattedText += `\n`;

    formattedText += jobDetails.Captura + '\n';

    let formatedclave = ''
    jobDetails.Claves.forEach((clave, index) => {
      formatedclave += `~${clave}~\n`;
    });
    setClaves(formatedclave);
    return formattedText;
  };

  const goto05 = () => { navigate('/P_Perfil') };
  const goto07 = () => { navigate('/P_Candidatos') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage.setItem('fetched', data);
      setIDlist(data.JobId || []);
      setFetchedData(data);
      setClaves(data.Claves)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const dataItems = [
    { label: 'CARGO',                   value: FetchedData.Charge_name 	? FetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name 			? FetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name 			? FetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name 		  ? FetchedData.R_name.toUpperCase() : '' }
  ];

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Popup Iteracion */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Instrucciones Adicionales {Aviso ? 'Aviso' : 'Preguntas'}:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Ingrese cualquier instrucción adicional que desee que Renee tenga en cuenta al generar la nueva iteracion {Aviso ? 'del Aviso' : 'de las Preguntas'}.
          </p>
          <textarea
            className='notas'
            style={{marginLeft:'2vh', marginRight:'2vh'}}
            value={instruccion}
            onChange={(e) => setInstruccion(e.target.value)}>
          </textarea>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleIterateText( instruccion ); setShowPopup(false) }}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Generar Iteracion </button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div className='typing-effect-container'>
				<h2 className='typing-effect' > 
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😍 También he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>
      
      <div className='MainBody'>

        {/* Aviso Generado */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          { Wait === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <textarea className='notas'
              value={Oferta}
              onChange={handleChange}
              spellCheck />
            )
          }
        </div>
        &nbsp;

        {/* Preguntas Generadas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          { Wait2 === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <div className='notas' style={{overflow:'hidden', gap:'0vh', paddingTop:'2vh'}}>

                <textarea className={Final1 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta1.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta1(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'65vh'}} 
                        onClick={() => {  if (Final1 === '') {
                                            setFinal1(Pregunta1)
                                          } else {
                                            setFinal1('')
                                          }}}> 
                    {Final1 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>

                <textarea className={Final2 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta2.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta2(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'65vh'}} 
                        onClick={() => {  if (Final2 === '') {
                                            setFinal2(Pregunta2)
                                          } else {
                                            setFinal2('')
                                          }}}> 
                    {Final2 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>    
                <textarea className={Final3 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta3.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta3(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'65vh'}} 
                        onClick={() => {  if (Final3 === '') {
                                            setFinal3(Pregunta3)
                                          } else {
                                            setFinal3('')
                                          }}}> 
                    {Final3 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>

                <textarea className={Final4 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta4.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta4(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'65vh'}} 
                        onClick={() => {  if (Final4 === '') {
                                            setFinal4(Pregunta4)
                                          } else {
                                            setFinal4('')
                                          }}}> 
                    {Final4 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>                
              </div>
            )
          }
        </div>
        &nbsp; 

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh'}}>

            <button className='Save' style={{height:'6vh'}} onClick={() => {setRegen(!Regen); setPublicar(false)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Aviso </button>
            <button className='Save' style={{height:'6vh'}} onClick={GeneratePreguntas}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Preguntas </button>
            
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta('')}> Regenerar nuevo Aviso Laboral</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas1)}>Regenerar como Consultora</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas2)}>Regenerar como Empresa Contratante</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas3)}>Regenerar como Empresa Confidencial</button>

            <button className='Save' style={{height:'6vh'}} onClick={() => {setShowPopup(true); setAviso(true)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Aviso </button>
            <button className='Save' style={{height:'6vh'}} onClick={() => {setShowPopup(true); setAviso(false)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Preguntas </button>

            <button 
                    style={{height:'6vh'}}
                    onClick={SaveData}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar Todo </button>
            <button 
                    style={{height:'6vh'}}
                    onClick={FetchData}> <i className='fa-solid fa-rotate-left'></i> &nbsp; Deshacer Cambios </button>

            <button 
                    style={{height:'6vh'}}
                    onClick={() => {setPublicar(!Publicar); setActual(false); setRegen(false)}}>Publicar el Anuncio</button>

            <button  
                    style={{height:'6vh'}}
                    onClick={() => {setActual(!Actual); setPublicar(false); setRegen(false)}}>Actualizar el Anuncio</button>
                    
              <button className = {Publicar && IDlist.length < 4 ? 'SubButon' : 'hidden'}
                      onClick={ExportToTrabajando}>Publicar en TRABAJANDO.COM</button>
              {/* <button className = {Publicar && IDlist.length < 4 ? 'SubButon' : 'hidden'}
                      onClick={ExportToYAPO}>Publicar en YAPO.CL</button> */}
              <button className = {Publicar && IDlist.length < 4 ? 'SubButoff' : 'hidden'}
                      >Publicar en Laborum</button>
              <button className = {Publicar && IDlist.length < 4 ? 'SubButoff' : 'hidden'}
                      >Publicar en Chile Trabajo</button>

              <button className = {Actual && IDlist.length > 0 && IDlist[IDlist.length - 1] !== 'renee_PSP' ? 'SubButon' : 'hidden'}
                      onClick={UpdateTrabajando}>Actualizar en TRABAJANDO.COM</button>
              {/* <button className = {Actual && IDlist.length > 0 && IDlist[IDlist.length - 1] !== 'renee_PSP' ? 'SubButoff' : 'hidden'}
                      onClick={ExportToYAPO}>Actualizar en YAPO.CL</button> */}
              <button className = {Actual && IDlist.length > 0 && IDlist[IDlist.length - 1] !== 'renee_PSP' ? 'SubButoff' : 'hidden'}
                      >Actualizar en Laborum</button>
              <button className = {Actual && IDlist.length > 0 && IDlist[IDlist.length - 1] !== 'renee_PSP' ? 'SubButoff' : 'hidden'}
                      >Actualizar en Chile Trabajo</button>
                      
          </div>
        </div>
      </div>

      <div className='bottom'>
        <button onClick={goto05}>volver</button>
        <button className={IDlist.length > 0 ? 'Save last-button' : 'hidden'} onClick={() => {SaveData(); goto07()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
