
//=============================================================================
// Pantalla Nuevo Usuario
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function Nuser() {

  const navigate = useNavigate();

  const [U_name, setU_name]   = useState('');
  const [U_mail, setU_mail]   = useState('');
  const [U_phone, setU_phone] = useState('');
  const [U_emp, setU_emp]     = useState('');
  const [Error, setError]     = useState('');
  const storedGrupos = JSON.parse(sessionStorage.getItem('gruposlista') || '[]');
  const rol                   = 'User';

  const [wait, setWait] = useState(false);

   const handleSubmit = async e => {
		setWait(true)
    setError('')
		e.preventDefault();
    const result = await LOGCALL({
			apicall: 'US_NEW',
			U_name,
      U_mail,
      U_phone,
      U_emp,
      rol
		});

    setWait(false)
    setTimeout(() => {
    }, 500);
    if (result) {
      alert(result.reply || ' ');
    } 
    else {
      alert(result.error || ' ');
    }
	};

  const goto01 = () => { navigate('/') };

  useEffect(() => {
  }, []);

  return (
    <div className='BackGround'>
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >Hey, Vamos a integrar a un nuevo miembro a renee!</h2>
			</div>
      <div className='MainBody'>

        <div>
          <div>
            <form onSubmit={handleSubmit} style={{display:'flex', width:'100%', height:'100%'}}>

              <label><strong>NOMBRE DEL USUARIO</strong>
               <input  type='text' 
                  style={{paddingLeft:'0vh', height:'4vh'}}
                  className={U_name ? 'highlightedItem' : ''}
                  onChange={e => setU_name(e.target.value)}/>
               </label>
               
               <label><strong>CORREO DEL USUARIO</strong>
               <input  type='mail' 
                  style={{paddingLeft:'0vh', height:'4vh'}}
                  className={U_mail ? 'highlightedItem' : ''}
                  onChange={e => setU_mail(e.target.value)}/>
               </label>

               <label><strong>TELEFONO DEL USUARIO</strong>
               <input  type='number' 
                  required
                  style={{paddingLeft:'0vh', height:'4vh'}}
                  className={U_phone ? 'highlightedItem' : ''}
                  onChange={e => setU_phone(e.target.value)}/>
               </label>

               <label>
                <strong>EMPRESA DEL USUARIO</strong>
                <select
                  required
                  className={U_emp ? 'highlightedItem' : ''}
                  style={{paddingLeft:'1vh', height:'5vh'}}
                  value={U_emp}
                  onChange={e => setU_emp(e.target.value)}
                >
                  <option value=''>Seleccione una empresa</option>
                  {storedGrupos.map((empresa, index) => (
                    <option key={index} value={empresa}>
                      {empresa}
                    </option>
                  ))}
                </select>
              </label>

              <button type='submit' >Guardar</button>

            </form>
            {Error && <div className='error'>{Error}</div>}
            {wait === false ? (
              <div>
                <br />
              </div>
            ) 
            : (
              <div className='loader-container'>
                <div className='pulsing-dot'></div>
              </div>
            )}
          </div>
        </div>
        
      </div>

      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <div></div>
      </div>
    </div>
  );
}
