
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Pendiente1() {
  const navigate = useNavigate();

  // const [Score, setScore] = useState('0');
  // const [FormID, setFormID] = useState(''); 
  // const [CompName, setCompName] = useState('');
  const [SortType, setSortType] = useState('score');
  // const [WHOreply, setWHOreply] = useState('');
  // const [SelectedRUT, setselectedRUT] = useState('');
  // const [FetchedData, setFetchedData] = useState(''); 
  // const [SelectedName, setSelectedName] = useState('');
  // const [selectedMail, setSelectedMail] = useState('');
  // const [SelectedSent, setSelectedSent] = useState(''); 
  // const [ScreenType, setScreentype] = useState('preguntas');
  // const [SelectedCategories, setSelectedCategories] = useState('');
  // const [Form, setForm] = useState([]); 
  // const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [SelectList3, setselectlist3] = useState([]);
  const [SelectList4, setselectlist4] = useState([]);
  const [Wait, setWait] = useState(false);
  // const [ShowPopup, setShowPopup] = useState(false);
  // const [ShowPopup3, setShowPopup3] = useState(false);
  // const [ShowPopup3, setShowPopup3] = useState(false);
  const [SelectedCVId, setSelectedCVId] = useState(null);

  async function Handleselect4(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select4: !cv.select4} 
          : cv
      )
    );
  
    const updatedSelectList4 = SelectList4.includes(name)
      ? SelectList4.filter(id => id !== name)
      : [...SelectList4, name];
  
    setselectlist4(updatedSelectList4);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList4: updatedSelectList4
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  // async function saveWarning( Namedata , warning, Rut ) {
  //   try {
  //     await APICALL({
  //       apicall: 'PD_update',
  //       code: sessionStorage.getItem('currentsave'),
  //       U_emp: sessionStorage.getItem('Grupo'),
  //       CVData: { name: Namedata, Warning: warning, RUT: Rut}
  //     });
  //   } catch (error) {
  //     console.error('Error during fetch:', error);
  //     return { token: null };
  //   }

  //   const data3 = await APICALL({
  //     apicall: 'PD_fetch',
  //     code: sessionStorage.getItem('process'),
  //     U_emp: sessionStorage.getItem('Grupo')
  //   });

  //   if (data3.CVData){
  //     setCVlist(data3.CVData);
  //   }
  // };

  // async function WhoCall(type , name, rut) {
  //   setWait(true)
  //   try {
  //     const data = await APICALL({
  //       apicall: 'WH_fetch',
  //       code: sessionStorage.getItem('process'),
  //       U_emp: sessionStorage.getItem('Grupo'),
  //       type: type,
  //       name: name,
  //       rut : rut
  //     });
  //     if (data.data.client) {
  //       if (data.data.client === 'Sandbox'){
  //         console.log('caso sandbox')
  //         setWHOreply(data.data)
  //         if (data.data.laboral.causas_totales || data.data.criminal.data?.length > 0){
  //           saveWarning( name , true, rut );
  //         } else {
  //           saveWarning( name , false, rut );
  //         }
  //       } else {
  //         console.log('caso produccion')
  //         setWHOreply(data)
  //         if (data.laboral.causas_totales || data.criminal.data?.length > 0){
  //           saveWarning( name , true, rut );
  //         } else {
  //           saveWarning( name , false, rut );
  //         }
  //       }
  //     } else {
  //       console.log('caso excepcion')
  //         setWHOreply(data.data)
  //     }

  //     setShowPopup3(false)
  //     setShowPopup3(true)
  //   } catch (error) {
  //     console.error('Error during fetch:', error);
  //     return { token: null };
  //   }

  //   setTimeout(() => {
  //     setWait(false);
  //   }, 500);
  // };

  // async function LookCV( data ) {

  //   const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


  //   try {
  //     const reply = await APICALL({
  //       apicall: apiCallType,
  //       code: sessionStorage.getItem('process'),
  //       U_emp: sessionStorage.getItem('Grupo'),
  //       id_code: data
  //     });

  //     function replaceNullStrings(obj) {
  //       for (const key in obj) {
  //         if (obj[key] === 'Null' || obj[key] === null) {
  //           obj[key] = ' - - -';
  //         } else if (typeof obj[key] === 'object' && obj[key] !== null) {
  //           replaceNullStrings(obj[key]);
  //         }
  //       }
  //       return obj;
  //     }
      
  //     let profile 

  //     if (apiCallType === 'CV_fetch'){
  //       profile = replaceNullStrings(reply.data)
  //     } else {
  //       profile = reply.data
  //     }

  //     const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
  //     const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

  //     // setCV(cv);
  
  //   } catch (error) {
  //     console.error('Error during fetch:', error);
  //     // setCV(null)
  //   }

  // };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota3 ? JSON.parse(a.Nota3) : 0;
        const notaB = b.Nota3 ? JSON.parse(b.Nota3) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars3 ? a.stars3 : 0;
        const starsB = b.stars3 ? b.stars3 : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const goto10 = () => { navigate('/P_Referencias') };  

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });

      // setFetchedData(data)
      // setCompName(data.E_name)
      // setFormID(data.FormID)
      // localStorage.removeItem('fetched');
      // localStorage['fetched'] = data;

      if (data.CVData){
        setCVlist(data.CVData)
        setselectlist3(Array.isArray(data.SelectList3) 
        ? data.SelectList3               
        : data.SelectList3               
          ? [data.SelectList3]         
          : []);
        setselectlist4(Array.isArray(data.SelectList4) 
        ? data.SelectList4               
        : data.SelectList4               
          ? [data.SelectList4]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Main */}
      <div className='typing-effect-container'>
        <h3 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          🚧 Esta ventana esta en Construcción 🚧
        </h3>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Lista de Candidatos</h3> 
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'score') {
                  setSortType('stars'); 
                } else if (SortType === 'stars') {
                  setSortType('name'); 
                } else {
                  setSortType('score'); 
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  % <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  ☆ <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  A-Z <i class='fa-solid fa-arrow-down-a-z'></i>
                </>
              )}
            </button>
          </div>
          <div  className='notas'
                style={{justifyContent:'flex-start'}}>
          {CVlist && CVlist.length > 0 ? (
            SortCVlist()
              .filter(reply => SelectList3.includes(reply.name))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={ reply.name ===  SelectedCVId ? 'start-button highlighted' : 
                                              reply.Warning === true ? 'start-button yellow' :
                                              SelectList4.includes(reply.name) ? 'start-button green' : 
                                                                                'start-button'}
                  onClick={() => {
                    // setWait(true);
                    // setScore(reply.stars3);
                    // LookCV(reply.id);
                    setSelectedCVId(reply.name)
                    // setSelectedName(reply.name)
                    // setSelectedMail(reply.mail);
                    // setselectedRUT(reply.RUT)
                    // setSelectedSent(reply.FormSent)
                  }}
                >
                  {reply.name}
                </button>
                <button className={ !reply.Nota3 ? 'middle-button' : 
                                                  JSON.parse(reply.Nota3) < 50 ? 'middle-button red' : 
                                                                                JSON.parse(reply.Nota3) < 70 ? 'middle-button yellow' : 
                                                                                                              'middle-button green'}
                        style={{maxWidth:'3vw'}}
                        onClick={() => {
                          setWait(true);
                        }}>
                  {reply.Nota3 ? JSON.parse(reply.Nota3) : ' - '}{'%'}
                </button>
                <button className={ !reply.stars3 ? 'middle-button' : 
                                                  JSON.parse(reply.stars3) < 3 ? 'middle-button red' : 
                                                                                JSON.parse(reply.stars3) < 5 ? 'middle-button yellow' : 
                                                                                                              'middle-button green'}
                        style={{maxWidth:'3vw'}}>
                  {reply.stars3 ? JSON.parse(reply.stars3) : ' - '}{'☆'}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        checked={SelectList4 ? SelectList4.includes(reply.name) : false}
                        onChange={() => {
                          Handleselect4(reply.name);
                        }}></input>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;

        {/* Respuestas Formularios */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId+' | ' : ''} 
            </h3>   
          </div>
          <div className='notas' style={{overflow: 'scroll', overflowX:'hidden'}}>
          <div>
            {Wait === true ? (
              <div>
                <div className='box'>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h3 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h3>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (  
              <div>
                <h1> Ventana en progreso </h1>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
      
      <div className='bottom'>
        <button onClick={goto10}>volver</button>
      </div>
    </div>
  );
}
